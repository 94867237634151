import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

import VCR_OSD_MONO_1 from "assets/fonts/VCR_OSD_MONO_1.001.ttf"

export default createGlobalStyle`
  ${normalize}

  /* Reset */
  /* https://medium.com/@elad/normalize-css-or-css-reset-9d75175c5d1e */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    word-break: break-word;
  }
  a{text-decoration:none; color:inherit; cursor:pointer;}
  button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
  figure{margin:0;}
  input::-moz-focus-inner {border:0; padding:0; margin:0;}
  ul, ol, dd{margin:0; padding:0; list-style-position: inside;}
  h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
  p{margin:0;}
  cite {font-style:normal;}
  fieldset{border-width:0; padding:0; margin:0;}
  /* Reset end */

  html {
    height: 100%;
    /* Hide scrollbar */
    /* Firefox */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    /* Smooth scroll */
    /* https://gomakethings.com/smooth-scrolling-links-with-only-css/ */
    /* If wider browsers support needed, use https://github.com/cferdinandi/smooth-scroll */
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
      html {
        scroll-behavior: auto;
      }
    }
  }

  body {
    height: 100%;
    font-family: unibody-8-new,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
  }

  #___gatsby {
    height: 100%;
    font-size: ${10 / 16}rem;

    ${(props) => props.theme.breakpoints.up("lg")} {
      font-size: ${12 / 16}rem;
    }
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }

    
  /* ---- FONTS ---- */
  @font-face {
    font-family: 'VCR OSD Mono';
    font-display: swap;
    src: local('VCR OSD Mono'),
      url(${VCR_OSD_MONO_1}) format('truetype');
    font-weight: normal;
  }
`
